import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import '../sass/main.scss';
import '../js/main.js';

ReactDOM.render(
    <App />,
    document.getElementById('app')
)