import React, { Component } from 'react';

export default class App extends Component {
    render() {
        return (
            <div id="test">
                <header id="header">
                    <h1>Infinite Engine</h1>
                    <p>Only your creativity is the limit<br />
                    Let your <b>infinite</b> dreams come true soon</p>
                </header>    
                <form id="signup-form" method="post" action="#">
                    <input type="email" name="email" id="email" placeholder="Coming Soon" disabled></input>
                    <input type="submit" value="Sign Up" disabled></input>
                    <span className="message"></span>
                </form>   
                <footer id="footer">
                    <ul className="icons">
                        <li>
                            <a href="https://github.com/kpcyrd" className="icon brands fa-github">
                                <span className="label">GitHub</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.patreon.com/knochey" className="icon brands fa-patreon">
                                <span className="label">Patreon</span>
                            </a>
                        </li>
                    </ul>



                    <ul className="copyright">
                        <li>© Henrik Hötger</li>
                        <li>
                            Special thanks to  
                            <a href="https://www.nvidia.com/"> NVIDIA</a>
                        </li>
                    </ul>
                </footer>
            </div>
        )
    }
}